<footer class="bg-light">
    <div class="row border">
    </div>
    <div class="row float-right mr-3 icons">
      <a href="https://www.linkedin.com/in/nicholas-lucasti" class="icon-block">
        <i class="fa fa-linkedin-square" style="color:#0077b5;"></i>
      </a>
      <a href="https://github.com/nlucasti" class="icon-block">
        <i class="fa fa-github ml-2" style="color:black"></i>
      </a>
  </div>
</footer>
