<!-- modify this form HTML and place wherever you want your form -->
<div class="container">
    <div class="row mt-5"><p></p></div>
    <div class="row mt-5">
        <div class="col-md-3 text-dark"><h1> </h1></div>
        <div class="col-md-6 text-dark">
            <h4 class="card-title ml-1">Contact Form</h4>
            <div class="card shadow">
                <div class="card-body">
                    <form action="https://formspree.io/xoqkpgjb" method="POST">
                        <div class="form-group pb-2">
                            <label for="InputEmail">Email Address</label>
                            <input type="email" class="form-control" name="email" id="InputEmail" aria-describedby="emailHelp" placeholder="Enter your email address">
                            <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                        </div>
                        <div class="form-group pb-2">
                            <label for="Subject">Subject</label>
                            <input type="text" class="form-control" id="Subject" placeholder="Enter Subject" name="subject">
                        </div>
                        <div class="form-group pb-3">
                            <label for="Email">Email Text</label>
                            <input type="text" class="form-control pb-5 pt-3" id="Email" placeholder="Describe your inquiry here." name="message">
                        </div>

                        <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>