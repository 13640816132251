<div class="container">
  <div class="row mt-5"><p></p></div>
  <div class="row mt-5">
    <div class="col-md-6 col-xs-12 order-sm-last">
      <img src="/assets/images/nick_v2.png"  style="width:100%" id="memoji"/>
    </div>
    <div class="col-md-6 col-xs-12 text-center text-md-left" style="padding:0px;">
      <h2 class="font-weight-normal text-dark" style="padding:0px;">About
      <span class="text-info font-weight-bold">Me</span></h2>
        <h5>I am Nicholas Lucasti, Aspiring Data Scientist <br class="mobile-break">from Atlanta, Georgia</h5>
        <p class="mt-3 text-infxo">Confident professional with excellent technical and communication skills specializing
          in data analysis, mining, and insights. Proficient with creating data pipelines,
          designing data warehouses, utilizing cloud architecture, and analyzing Big-Data to create
          an impactful story. Specialize in creative thinking to find unique solutions for every problem.
        </p>
      <div class="row container left-mobile">
        <div class="col-md-6 col-xs-12 text-center text-md-left" >
          <div class="row mb-1 mt-5 mt-md-0">
            <div class="col" style="padding:0px">
              <h4 class="">Experiences</h4>
              <h6 class="mb-0">Data Analyst</h6>
              <p style="font-size:12px;">AT&T</p>
              <p class="text-info" style="font-size:12px;margin-top:-16px;">July 2019-Present</p>
              <h6 class="mb-0">Design Release Engineer Intern</h6>
              <p style="font-size:12px;">General Motors</p>
              <p class="text-info" style="font-size:12px; margin-top:-16px;">June 2018-Aug 2018</p>
              <h6 class="mb-0">Manufacturing Intern</h6>
              <p style="font-size:12px;">General Motors</p>
              <p class="text-info" style="font-size:12px;margin-top:-16px; margin-bottom:72px;">June 2017-Aug 2017</p>
          </div>
        </div>
      </div>
        <div class="col-md-6 col-xs-12 text-center text-md-left" style="padding:0px;">
            <h4>Skills</h4>
            <div class="d-flex skills mt-1">
            <button type="button" class="btn btn-info" style="font-size:12px;">
            Python
            </button>
            <button type="button" class="btn btn-info ml-1" style="font-size:12px;">
            SQL
            </button>
            <button type="button" class="btn btn-info ml-1" style="font-size:12px;">
            Communication
            </button>
          </div>
          <div class="d-flex skills mt-1">
            <button type="button" class="btn btn-info" style="font-size:12px;">
            HTML
            </button>
            <button type="button" class="btn btn-info ml-1" style="font-size:12px;">
            ML
            </button>
            <button type="button" class="btn btn-info ml-1" style="font-size:12px;">
            Cloud
            </button>
          </div>
          <div class="row ml-1 mt-5 mb-5"><p></p></div>
      </div>
    </div>

  </div>
</div>
