<div class="container">
  <div class="row mt-5"><p></p></div>
  <div class="row"><p></p>
  <div class="col mr-5 bg-md-info"><p></p></div>
  <div class="col-md-8"><p></p></div></div>
  <div class="row">
    <div class="col mr-md-5 text-dark banner" style="">
        <div class="row mt-3">
            <h3 class="mx-auto">{{resume.name}}</h3>
        </div>
        <div class="row">
          <h6 class="mx-auto">Data Analyst at AT&T</h6>
      </div>
        <img src="../../assets/images/portrait.jpeg" class="mt-3 mb-5 mx-auto d-block" style="width:75%;">
        <div class="row">
          <p class="summary">Experienced Data Analyst that has worked with data streaming, warehousing, 
            and statistical analysis to generate actionable insights. Competent with Full Stack Development and 
            Data Engineering principals to round out skill set and drive an end-to-end approach to analyzing data. 
          </p>
      </div>
    </div>
    <div class="col-md-8 mt-3 text-dark">
          <i class="fa fa-briefcase mt-1 ml-1"></i>
          <h5 class="card-title ml-2" style="display:inline-block;">Work Experience</h5>
          <div class="card shadow" style="width: 100%;">
            <div class="card-body">
              <!-- <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                <ul style="padding:0px;">
                  <li *ngFor ="let experience of resume.experiences">
                    <img src="{{experience.logo_link}}" class ="logo">
                    <h5 style="margin-bottom:5px;">{{experience.title}}</h5>
                    <h6 style="margin-bottom:2px;">{{experience.company}}</h6>
                    <p class="dates">{{experience.date}}</p>
                    <p>{{experience.description}}</p>
                  </li>
                </ul>

              </div>
          </div>
          <div class=mt-4>
            <i class="fa fa-graduation-cap mt-1 ml-1" style = ""></i>
            <h5 class="card-title ml-2" style="display:inline-block;">Education</h5>
            <div class="card shadow" style="width: 100%;">
              <div class="card-body" style="width: 100%;">
                <ul style="padding:0px;">
                  <li *ngFor ="let education of resume.education">
                    <img src="{{education.logo_link}}" class ="logo">
                    <h5 style="margin-bottom:5px;">{{education.major}}</h5>
                    <h6 style="margin-bottom:2px;">{{education.school}}</h6>
                    <p class="gpa" style="margin-bottom:2px;">GPA: {{education.gpa}}</p>
                    <p class="dates">{{education.date}}</p>
                  </li>
                </ul>
              </div>
            </div>
            </div>
            <div class="mt-4" style="margin-bottom: 100px;"> 
            <i class="fa fa-code-fork fa-lg mt-1 ml-1"></i>
            <h5 class="card-title ml-2" style="display:inline-block;">Projects</h5>
            <div class="card shadow" style="width: 100%;">
              <div class="card-body mb-5" style="margin-bottom:100px">
                <ul>
                  <li>
                    COMING SOON!<br>...Hopefully :)
                  </li>
                </ul>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>


  <!-- <div class="row mb-5" style="margin-bottom:"><p></p></div> -->

