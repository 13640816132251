import { Component, OnInit } from '@angular/core';
import ResumeJson from '../../assets/data/resume.json';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  resume: any = ResumeJson;
}
