<nav class="navbar navbar-expand-md bg-light navbar-light fixed-top">
  <div class="container pl-0">
  <a class="navbar-brand text-dark font-weight-bold" style ="font-size: 28px;" href="#">{{title}}
  <span class="navbar-brand text-info font-weight-normal" style ="font-size: 28px;">Portfolio</span>
</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse" data-toggle="collapse" data-target="#navbarCollapse.show">
    <ul class="navbar-nav ml-auto">

      <li class="nav-item">
        <a class="nav-link text-info" routerLink="/home">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-info" routerLink="/resume">Resume</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-info" routerLink="/contact">Contact</a>
      </li>

    </ul>
  </div>
</div>
</nav>
